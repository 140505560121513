import { faDAndD } from '@fortawesome/free-brands-svg-icons/faDAndD'
import { faSwords } from '@fortawesome/pro-duotone-svg-icons/faSwords'
import { faBowArrow } from '@fortawesome/pro-duotone-svg-icons/faBowArrow'
import { faRaygun } from '@fortawesome/pro-duotone-svg-icons/faRaygun'
import { faChess } from '@fortawesome/pro-duotone-svg-icons/faChess'
import { faBookOpen } from '@fortawesome/pro-duotone-svg-icons/faBookOpen'
import { faAlbumCollection } from '@fortawesome/pro-duotone-svg-icons/faAlbumCollection'
import { faListMusic } from '@fortawesome/pro-duotone-svg-icons/faListMusic'
import { faMusic } from '@fortawesome/pro-duotone-svg-icons/faMusic'
import { faInfinity } from '@fortawesome/pro-duotone-svg-icons/faInfinity'
import { faWaveformLines } from '@fortawesome/pro-duotone-svg-icons/faWaveformLines'
import { faBullseyeArrow } from '@fortawesome/pro-duotone-svg-icons/faBullseyeArrow'
import { faFileAudio } from '@fortawesome/pro-duotone-svg-icons/faFileAudio'
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck'
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers'
import { faUserLock } from '@fortawesome/pro-duotone-svg-icons/faUserLock'
import { faCloudArrowUp } from '@fortawesome/pro-duotone-svg-icons/faCloudArrowUp'
import { faVolume } from '@fortawesome/pro-duotone-svg-icons/faVolume'
import { faCartShopping } from '@fortawesome/pro-duotone-svg-icons/faCartShopping'
import { faToggleOff } from '@fortawesome/pro-duotone-svg-icons/faToggleOff'
import { faToggleOn } from '@fortawesome/pro-duotone-svg-icons/faToggleOn'
import { TagKey } from 'modules-core/searchSystem'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export type iTagIconMap = Record<TagKey, IconDefinition>

export const inactiveTagIconMap: iTagIconMap = {
	[TagKey.Genre_GeneralFantasy]: faSwords,
	[TagKey.Genre_DungeonsAndDragons]: faDAndD,
	[TagKey.Genre_Pathfinder]: faBowArrow,
	[TagKey.Genre_SciFi]: faRaygun,
	[TagKey.Genre_BoardGame]: faChess,
	[TagKey.Kind_Adventure]: faBookOpen,
	[TagKey.Kind_Soundset]: faAlbumCollection,
	[TagKey.Kind_Mood]: faListMusic,
	[TagKey.Kind_Music]: faMusic,
	[TagKey.Kind_Loop]: faInfinity,
	[TagKey.Kind_SFX]: faWaveformLines,
	[TagKey.Kind_Oneshot]: faBullseyeArrow,
	[TagKey.Kind_Sample]: faFileAudio,
	[TagKey.Library_AvailableToPlay]: faVolume,
	[TagKey.Library_AvailableToBuy]: faCartShopping,
	[TagKey.Playing_NowPlaying]: faToggleOff,
	[TagKey.Status_OfficialContent]: faCheck,
	[TagKey.Status_CommunityContent]: faUsers,
	[TagKey.Status_MyContent]: faUserLock,
	[TagKey.Status_PendingReview]: faCloudArrowUp,
}

export const activeTagIconMap: iTagIconMap = {
	...inactiveTagIconMap,
	[TagKey.Playing_NowPlaying]: faToggleOn,
}

export const tagIconMap: iTagIconMap = activeTagIconMap

export type FontAwesomeIconName =
    | 'swords'
    | 'd-and-d'
    | 'bow-arrow'
    | 'raygun'
    | 'chess'
    | 'book-open'
    | 'album-collection'
    | 'list-music'
    | 'music'
    | 'infinity'
    | 'waveform-lines'
    | 'bullseye-arrow'
    | 'file-audio'
    | 'check'
    | 'users'
    | 'user-lock'
    | 'cloud-arrow-up'
    | 'volume'
    | 'cart-shopping'
    | 'toggle-off'
    | 'toggle-on'
