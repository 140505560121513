import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { iSearchResult } from 'modules-core/searchSystem'

import { TagKey } from 'modules-core/searchSystem'
import { tagIconMap } from '../tagIconMap'

import { faStop } from '@fortawesome/pro-solid-svg-icons/faStop'
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay'
import { css } from '@emotion/css'
import GradientIconBackground from 'modules-ui/ui/GradientIconBackground'
interface iProps {
  item: iSearchResult
  isPlaying?: boolean
}

const searchItemToIcon = (item: iSearchResult) => {
	if (item.title_for_mood !== undefined) {
		return <FontAwesomeIcon icon={tagIconMap[TagKey.Kind_Mood]} transform='right-0.5' />
	}
	if (item.title_for_music !== undefined) {
		return <FontAwesomeIcon icon={tagIconMap[TagKey.Kind_Music]} transform='left-1 top-0.5' />
	}
	if (item.title_for_loop !== undefined) {
		return <FontAwesomeIcon icon={tagIconMap[TagKey.Kind_Loop]} transform='right-0.5' />
	}
	if (item.title_for_sfx !== undefined) {
		return <FontAwesomeIcon icon={tagIconMap[TagKey.Kind_SFX]} transform='right-0.5' />
	}
	if (item.title_for_oneshot !== undefined) {
		return <FontAwesomeIcon icon={tagIconMap[TagKey.Kind_Oneshot]} transform='right-1' />
	}
}

export const SearchItemIcon = ({ item, isPlaying }: iProps) => {
	//this will lose sync if the item finishes playing or is stopped in the master interface
	isPlaying ??= false
	return (
		<>
			{item.icon ? (
				<img className={iconStyle} alt='Play' src={item.icon} />
			) : (
				<GradientIconBackground variant={isPlaying? 'playing' : 'default'}>
					{searchItemToIcon(item) ??
            <FontAwesomeIcon icon={isPlaying ? faStop : faPlay} transform={isPlaying ? 'right-0.5' : 'right-2'} />
					}
				</GradientIconBackground>
			)}
		</>
	)
}

const iconStyle = css`
  width: 50px;
  height: 50px;
`
