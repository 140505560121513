import React from 'react'
import {
	DialogTitle,
	DialogContent,
	Checkbox,
	Dialog,
	Typography,
} from '@mui/material'
import { useRecoilState } from 'recoil'
import { getApp } from 'app'
import { elasticFilterAtom } from '../searchState'
import { setCyid } from 'modules-ui/utility/testUtility'
import { inactiveTagIconMap, activeTagIconMap } from '../tagIconMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { tagTypeMapArray, tagMapArray } from 'modules-core/searchSystem'
import { css } from '@emotion/css'
import { SimpleTooltip } from 'modules-ui/ui'

interface iProps {
  open: boolean
  set_open: (val: boolean) => void
}

const { searchSystem } = getApp()
const { elasticFilterSystem } = searchSystem

export const SearchElasticFilterDialog = ({ open, set_open }: iProps) => {
	return (
		<Dialog onClose={() => set_open(false)} open={open}>
			<DialogTitle>Filter</DialogTitle>
			<DialogContent>
				<SearchElasticFilter />
			</DialogContent>
		</Dialog>
	)
}

export const SearchElasticFilter = () => {
	const [elasticFilterState, set_elasticFilterState] = useRecoilState(elasticFilterAtom)
	const [allChecked, set_allChecked] = React.useState(true)
	const [allIndeterminite, set_allIndeterminite] = React.useState(false)

	React.useEffect(() => {
		const allChecked = elasticFilterState.every((s) => s)
		const allUnchecked = elasticFilterState.every((s) => !s)
		set_allChecked(allChecked)
		set_allIndeterminite(!allChecked && !allUnchecked)
		return () => {}
	}, [elasticFilterState])

	const handleChange = (index: number, checked: boolean) => {
		// console.dir(checked);
		set_elasticFilterState((state) => {
			const newState = [...state]
			newState[index] = checked
			return newState
		})
	}

	const handleChangeAll = (checked: boolean) => {
		set_allChecked(checked)
		set_allIndeterminite(false)
		set_elasticFilterState((state) => {
			state = state.map((s) => checked)
			return state
		})
	}
	return (
		<div className={containerStyle}>
			{tagTypeMapArray.map(({ key, displayName }, i) => (
				<div key={i} className={groupStyle}>
					<Typography variant='body2' fontSize={'1.4rem'}> {displayName}</Typography>
					{tagMapArray
						.filter((tag) => tag.tagType === key)
						.map((tag, i) => (
							<div key={i} className={tagStyle}>
								<SimpleTooltip title={tag.name}>
									<Checkbox
										className={tagButtonStyle}
										disableRipple
										size='small'
										color='primary'
										{...setCyid('search-elastic-filter-item')}
										onChange={(e) => handleChange(tag.key, e.target.checked)}
										checked={elasticFilterState[tag.key]}
										icon={<FontAwesomeIcon icon={inactiveTagIconMap[tag.key]} size='1x' />}
										checkedIcon={<FontAwesomeIcon icon={activeTagIconMap[tag.key]} size='1x' />}
									/>
								</SimpleTooltip>
							</div>
						))}
				</div>
			))}
		</div>
	)
}

const containerStyle = css`
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.6rem;
  text-align: center;
  width: 350px;
`

const groupStyle = css`
  display: inline-block;
  padding: 0 1.6rem 1.6rem 0;
  font-size: 1.6rem;
  line-height: 24px;
  white-space: nowrap;
`

const tagStyle = css`
  display: inline-block;
`

const tagButtonStyle = css`
  font-size: 1.92rem;
  padding: 0 0.33em 0 0;
  line-height: 28.8px;
  &:hover {
    color: #ffffff;
    background-color: transparent;
  }
`
